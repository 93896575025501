export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="269.317" height="290.563" viewBox="0 0 269.317 290.563">
        <g transform="translate(-0.001)">
            <g transform="translate(0.001)">
                <path d="M137.81,0C69.166,0,8.054,23.558.643,125.256s50.015,171.839,131.53,164.827,139.248-52.623,137.086-152.55C267.406,51.613,210.06,0,137.81,0" transform="translate(0)" fill="red"/>
                <path d="M154.456,45.981c-55.611,0-105.128,19.091-111.129,101.488S83.844,286.691,149.889,281.007s112.817-42.635,111.075-123.591C259.458,87.8,213,45.981,154.456,45.981" transform="translate(-16.087 -17.462)" fill="yellow"/>
            </g>
        </g>
    </svg>
)
