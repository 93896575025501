import React, { useState } from 'react'
import { useIsMobile, useIsTablet } from 'Utils'

import { useQuery } from 'urql'

export const MainContext = React.createContext()

const OPTIONS_QUERY = `
    query {
        options {
            options {
                logo {
                    mediaItemUrl
                }
            }
        }
    }
`

export const ContextProvider = ({
    children,
}) => {
    const [activeSection, setActiveSection] = useState('')
    const [modalData, setModalData] = useState(false)

    const openModal = props => {
        setModalData({
            ...props,
            isVisible: true
        })
    }

    const closeModal = () => {
        setModalData({
            ...modalData,
            isVisible: false
        })
    }

    const [{ data }] = useQuery({
        query: OPTIONS_QUERY
    })

    const isMobile = useIsMobile()
    const isTablet = useIsTablet()

    return (
        <MainContext.Provider
            value={{
                openModal,
                closeModal,
                modalData,
                isMobile,
                isTablet,
                options: data ? data.options.options : null,
                activeSection,
                setActiveSection,
            }}
        >
            {children}
        </MainContext.Provider>
    )
}
