import { useQuery } from "urql"

import Masonry from 'react-masonry-component'

import FunBiology from 'Images/fun-biology'

import Slide from './Slide'

import './FunBiologyMasonry.sass'
import React from "react"

const FUN_BIOLOGY = `
    query {
        funBiology ( first: 100 ) {
            nodes {
                title
                featuredImage {
                    node {
                        mediaItemUrl
                    }
                }
                funBiologyFields {
                    background
                }
            }
        }
    }
`

const FunBiologyMasonry = ({
    title,
    copy,
    scrollId
}) => {
    const [{
        data,
    }] = useQuery({
        query: FUN_BIOLOGY
    })

    const funBiology = data ? data.funBiology.nodes : null

    return (
        <div className="FunBiologyMasonry-cont" id={scrollId}>
            { FunBiology }
            <div
                className="container"
                id={'brand'}
            >
                <div className="FunBiologyMasonry-content">
                    <h2
                        className="FunBiologyMasonry-content-title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    {copy &&
                        <div
                            className="FunBiologyMasonry-content-copy"
                            dangerouslySetInnerHTML={{ __html: copy }}
                        />
                    }
                </div>
                { funBiology ?
                    <Masonry
                        className={'MasonryGrid'}
                        options={{
                            transitionDuration: 0,
                            columnWidth: '.FunBiologyMasonry-sizer',
                            itemSelector: '.FunBiologyMasonry-image',
                            percentPosition: true,
                        }}
                    >
                        { funBiology.map((item, index) => {
                            const {
                                title,
                                featuredImage,
                                funBiologyFields,
                            } = item

                            return (
                                <React.Fragment key={index}>
                                    <div className="FunBiologyMasonry-sizer" />
                                    <div
                                        style={{
                                            transitionDelay: `${index * 0.1}s`,
                                        }}
                                        className={`FunBiologyMasonry-image`}
                                    >
                                        { featuredImage &&
                                            <Slide
                                                title={ title }
                                                featuredImage={ featuredImage }
                                                funBiologyFields={ funBiologyFields }
                                            />
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        }) }
                    </Masonry>
                :
                    <div>Loading...</div>
                }
            </div>
        </div>
    )
}

export default FunBiologyMasonry
