import React, { useEffect, useState } from "react"
// import { useNavigate } from 'react-router-dom'

import './Main.sass'

import Header from "Components/Header/Header"
import FlexibleContentRow from "./FlexibleContentRow"

import TailSpin from 'Images/tailSpin'

import { useQuery, useMutation } from "urql"

// const CHECK_AUTHENTICATION = `
//     mutation (
//         $token: String!
//     ) {
//         checkAuthentication (
//             input: {
//                 token: $token
//                 clientMutationId: "uniqueId"
//             }
//         ) {
//             success
//         }
//     }
// `

const FLEXIBLE_CONTENT = `
    query {
        page (
            id: 2
            idType: DATABASE_ID
        ) {
            components {
                rows {
                    ...on Page_Components_Rows_Hero {
                        title
                        copy
                        subcopy
                        link {
                            title
                            url
                        }
                        image {
                            mediaItemUrl
                        }
                        topHero
                        scrollId
                    }
                    ... on Page_Components_Rows_VideoAndText {
                        copy
                        title
                        video {
                            mediaItemUrl
                        }
                        videoPoster {
                            mediaItemUrl
                        }
                        decoration {
                            mediaItemUrl
                        }
                        scrollId
                    }
                    ... on Page_Components_Rows_VideoTabs {
                        splitTitle {
                            firstPart
                            secondPart
                        }
                        videos {
                            customerVideo {
                                mediaItemUrl
                            }
                            customerVideoPoster {
                                mediaItemUrl
                            }
                            employeeVideo {
                                mediaItemUrl
                            }
                            employeeVideoPoster {
                                mediaItemUrl
                            }
                        }
                    }
                    ... on Page_Components_Rows_Timeline {
                        years {
                            copy
                            offsetPercent
                            mobileOffsetPercent
                            year
                            image {
                                mediaItemUrl
                            }
                        }
                        titles {
                            left
                            right
                        }
                    }
                    ... on Page_Components_Rows_VideoSlider {
                        title
                        copy
                        slides {
                            title
                            placeholderImage {
                                mediaItemUrl
                            }
                            video {
                                mediaItemUrl
                            }
                            colourScheme
                        }
                        decoration1 {
                            mediaItemUrl
                        }
                        decoration2 {
                            mediaItemUrl
                        }
                        scrollId
                    }
                    ... on Page_Components_Rows_FunBiologyMasonry {
                        title
                        copy
                        scrollId
                    }
                    ... on Page_Components_Rows_ImageAndText {
                        title
                        copy
                        image {
                            mediaItemUrl
                        }
                    }
                    ... on Page_Components_Rows_Text {
                        title
                        copy
                    }
                }
            }
        }
    }
`

// Auto-import all components in FlexibleContent
const importContext = require.context('Components/FlexibleContent', true, /.jsx$/)
const allComponents = {}

importContext.keys().forEach(key => {
    const fileSegments = key.split('/')
    const fileName = fileSegments[fileSegments.length - 1]

    const resource = require(`Components/FlexibleContent/${key.replace('./','')}`).default
    const namespace = fileName.replace('.jsx', '')
    allComponents[namespace] = resource
})

function Main() {
    // let navigate = useNavigate()

    // const [{}, checkAuthentication] = useMutation(CHECK_AUTHENTICATION)

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    // useEffect(() => {
    //     const token = localStorage.getItem('token')

    //     if ( !token ) {
    //         navigate('/login')
    //     } else {
    //         setInterval(function checkAuthenticationInterval() {
                
    //             checkAuthentication({
    //                 token,
    //             }).then(response => {
    //                 if (response.data.checkAuthentication.success === true) {
    //                     setIsAuthenticated(true)
    //                 } else {
    //                     localStorage.removeItem('token')
    //                     navigate('/login')
    //                 }
        
    //                 return false
    //             })

    //             return checkAuthenticationInterval;
    //         }(), 60000)
    //     }
    //     setIsAuthenticated(true)

    // }, [navigate, checkAuthentication])

    const [showLoader, setShowLoader] = useState(true)

    const [{
        data,
        fetching
    }] = useQuery({
        query: FLEXIBLE_CONTENT
    })

    useEffect(() => {
        document.title = `Medibank Fun Biology`
    }, [])

    useEffect(() => {
        if ( fetching || !data || !isAuthenticated ) {
            setTimeout(() => {
                setShowLoader(false)
            }, 2500)
        }
    }, [fetching, data, isAuthenticated])

    return (
        <>
            <div className={`mainloading ${ !showLoader ? 'hidden' : '' }`}>
                { TailSpin }
            </div>
            <Header 
                isLoading={showLoader}
            />
            <div className="flexible-content">
                { data && data.page.components.rows.map((row, index) => {
                    const key = row.__typename.replace('Page_Components_Rows_', '')

                    const Component = allComponents[key]

                    if(!Component){
                        console.log(`component missing for ${ key }`)
                        console.log(allComponents)

                        return null 
                    }

                    return (
                        <FlexibleContentRow 
                            Component={Component}
                            row={row}
                            className={key}
                            key={index}
                        />
                    )
                })}
            </div>
        </>
    )
}

export default Main
