import './Text.sass'

const Text = ({
    title,
    copy,
}) => {
    return (
        <div className="container">
            <div className="Text-content">
                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                { copy &&
                    <div
                        className="Text-content-copy" 
                        dangerouslySetInnerHTML={{ __html: copy }} 
                    />
                }
            </div>
        </div>
    )
}

export default Text
