import Button from 'Components/Button/Button'

import './Hero.sass'

const Hero = ({
    title,
    copy,
    subcopy,
    link,
    image,
    topHero,
    scrollId
}) => {
    return (
        <div className={`container ${ topHero && 'topHero' }`} id={scrollId}>
            <div className="Hero-content">
                <strong 
                    style={{
                        transitionDelay: '0.5s'
                    }} 
                    className="Hero-content-copy" 
                    dangerouslySetInnerHTML={{ __html: title }} 
                />
                { copy &&
                    <div 
                        style={{
                            transitionDelay: '0.7s'
                        }}
                        className="Hero-content-copy" 
                        dangerouslySetInnerHTML={{ __html: copy }} 
                    />
                }
                { subcopy &&
                    <div 
                        style={{
                            transitionDelay: '0.9s'
                        }}
                        className="Hero-content-subcopy" 
                        dangerouslySetInnerHTML={{ __html: subcopy }} 
                    />
                }
                { link &&
                    <Button
                        label={ link.title }
                        href={ link.url }
                        className="white"
                    />
                }
            </div>
            { image &&
                <div className="Hero-image">
                    <img src={ image.mediaItemUrl } alt={ title } />
                </div>
            }
        </div>
    )
}

export default Hero
