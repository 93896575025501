import React from 'react'

import { useIsMobile } from 'Utils'

import './Timeline.sass'
import { reportEvent } from 'Utils/analytics'

function Timeline({
    titles,
    years,
}) {
    const { left, right } = titles

    const isMobile = useIsMobile()

    const handleDotHoverEvent = () => {
        reportEvent({
            category: "User Interaction",
            action: "Hovered on timeline"
        })
    }

    return (
        <>
            <h1>
                { left } <span>{ right }</span>
            </h1>
            <div className="inner">
                <div className="container">
                    {!isMobile &&
                        <div className="Timeline-squiggleCont" />
                    }
                    <div className="Timeline-years">
                        <div className="Timeline-years-cont">
                            <div className="Timeline-years-squiggleCont" />
                            {years.map((year, index) => {
                                const {
                                    copy,
                                    image,
                                    offsetPercent,
                                    mobileOffsetPercent,
                                    year: date
                                } = year

                                const offset = !isMobile ? `translateX(${offsetPercent}%)` : `translateY(${mobileOffsetPercent || 0}%)`

                                return (
                                    <div
                                        className={`Timeline-years-year ${ offsetPercent < 0 ? 'left' : ''} ${ !index ? 'first' : ''}`}
                                        key={index}
                                        style={{ transform: offset }}
                                    >
                                        <div className="blueDot" onMouseEnter={handleDotHoverEvent}>
                                            <span>{date}<i className="fe fe-arrow-up-right" /></span>
                                            <div className="contentBox" data-year={date}>
                                                {image && <img src={image.mediaItemUrl} alt="" />}
                                                <div
                                                    className="copy"
                                                    dangerouslySetInnerHTML={{ __html: copy }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Timeline
