import { useInView } from 'react-intersection-observer'

const Slide = ({
    title,
    featuredImage,
    funBiologyFields,
}) => {
    const {
        background,
    } = funBiologyFields

    const { ref, inView } = useInView({
        threshold: 0.15,
    })

    return (
        <div
            className={ `${ inView && 'in-view' } ${ background === 'white' ?  'white' : '' }` }
            ref={ref}
        >
            <img
                src={featuredImage.node.mediaItemUrl}
                alt={title}
            />
        </div>
    )
}

export default Slide
