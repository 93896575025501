export default (
    <svg xmlns="http://www.w3.org/2000/svg" width="2879.78" height="1101.245" viewBox="0 0 2879.78 1101.245">
        <defs>
            <clipPath id="clip-path">
                <path d="M0,1101.245H2879.78V0H0Z" transform="translate(0 0.001)" fill="#dd0822"/>
            </clipPath>
            <clipPath id="clip-path-2">
                <rect width="3973.839" height="2871.033" fill="none"/>
            </clipPath>
        </defs>
        <g transform="translate(0 -0.001)" clipPath="url(#clip-path)">
            <g transform="matrix(0.999, -0.035, 0.035, 0.999, -325.451, -1867.613)">
            <g clipPath="url(#clip-path-2)">
                <path d="M107.942,1712.87c71.65,15.687,149.221-5.5,214.6-33.386,39.884-17.263,76.747-38.6,112.431-62.466,19.935-13.98,39.472-26.66,60.522-39.792,188.193-112.717,325.253-290.931,443.12-472.035,172.3-266.23,325.36-599.726,639.222-716.435,81.474-28.673,170.14-38.938,255.95-31.267,100.464,8.656,193.741,46.071,277.911,100.449,22.121,13.762,46.779,31.151,70.516,44.4,97.011,53,210.189,79.452,320.443,56.948,45.466-9.214,90.722-26.059,129.447-52.715,109.3-83.114-2.865-244.986-119.467-170.106l1.581-1.135c.286-.194.034-.063.049-.092,0,0-.3.111-.592.257-16.468,8.306-34.036,13.859-52.53,17.68-71.893,14.077-139.557-3.356-207.91-40.742-61.283-34.269-126.159-66.845-194.342-87.652-279.3-88.511-594.873-4.384-797.366,204.743-238.42,235.3-348.34,555.222-543.593,819.337-56.177,75.98-117.508,146.714-190.259,206.251-36.586,30.452-77.042,56.211-116.732,84.442-36.936,26.845-77.5,52.225-118.958,70.578-47.031,20.817-96.579,35.646-147.606,33.692a122.779,122.779,0,0,1-13.951-1.445l-1.867-.335c-.315-.053-1.489-.349-.451-.058-46.319-11.769-67.543,54.926-20.167,70.884" transform="translate(312.664 231.996)" fill="#006f6a"/>
                <path d="M140.523,2077.584c91-29.386,173.826-79.239,250.737-135.149C554.126,1822.768,680.683,1660.668,778.8,1485.32c92.967-155.142,171.236-321.743,278.828-466.818,60.134-79.923,132.526-148.368,213.486-207.347,80.757-59.222,164.743-110.7,258.079-146.661,50.111-19.988,102.049-36.475,152.189-63.319a504.751,504.751,0,0,0,76.586-50.344c92.574-68.5,152.941-187.675,94.95-298.859-21.224-42.818-74.991-59.465-116.053-34.259-42.469,24.478-47.138,80.611-29.061,109.648,8.694,31.616-1.353,59.367-24.556,86.619-37.207,43.007-83.24,69.182-135.673,92.9-47.672,21.326-99.9,43.584-146.724,69.745-48.5,26.69-94.9,57.185-139.218,89.849-161.994,119.055-277.262,253.322-372,430.091-64.086,116.989-119.884,233.1-182.379,348.684-35.825,67.6-74.642,135.212-119.588,197.1C483.94,1786.24,351.3,1912.836,192.112,1993.122c-22.311,11.051-45.213,21.37-68.673,29.182-34.375,11.837-20.953,64.508,17.083,55.28" transform="translate(398.949 -205.857)" fill="#eaa6c7"/>
                <path d="M2308.326,239.3c1.343-.936,1.038-.761,1.183-.892.165-.136.029-.092-.063-.073a3.594,3.594,0,0,0-.742.237c-7.012,3.21-13.917,6.173-21.263,9.218-77.265,32.848-162.129,32.46-244.986,9.116l-4.52-1.236c-1.169-.335-3.714-1.052-4.873-1.484-49.786-16.3-99.145-31.752-150.613-44.064-151.176-38.4-321.127-37-464.02,33.478-139.024,66.4-252.769,180.1-336.11,307.181-64.512,96.953-116.543,198.711-178.786,295.072C814.014,986.208,701.752,1113.6,567.292,1211.866c-121.518,87.182-280.341,156.873-429.829,107.558-4.321-1.474-8.287-3.2-12.317-4.98l-1.765-.824c-.344-.17-1.319-.664-.96-.456-37.566-20.255-70.171,33.313-32.668,56.991,28.93,15.546,61.317,24.173,93.379,29.483C453.9,1438.508,708,1230.593,875.175,1038.927c76.461-88.6,140.794-188.3,201.742-286.9,58.907-94.456,117.809-188.8,196.932-265.3,112.543-110.356,257.744-182.384,417.8-169.461,77.707,5.324,156.228,28.377,230.046,57.365,23.853,8.951,56.662,22.97,80.5,32.513,37.833,14.906,78.623,26.782,119.618,33.561a490.476,490.476,0,0,0,181.855-3.089c34.342-7.327,68.819-19.755,99.121-38.638,48.564-26.835,65.87-88.365,34.191-135.023A92.631,92.631,0,0,0,2308.326,239.3" transform="translate(281.543 569.75)" fill="#9badd3"/>
                <path d="M649.492,497.22c48.792-69.473,81.528-157.76,63.019-243.187C693.9,165.522,616.722,106.392,538.8,71.343,336.6-21.115,135.851,79.6,49.993,276.959,25.083,333.752,8.34,394.031,1.779,455.629c-2.9,35.2-2.638,71.519,3.714,106.316l1.78,10.561.912,5.276,1.266,5.193,5.15,20.778c61.525,215.9,282.93,355.832,495.321,391.618a598.1,598.1,0,0,0,124.641,7.991c26.694-1.489,54.6-3.942,80.922-7.87C935.278,964.6,1139.362,861.047,1341.8,780.858c35.738-14.053,107.723-43.307,143.145-58.2,183.727-76.092,366.708-160.549,564.92-187.515,214.354-31.209,500.844,28.222,663.541,172.807A573.07,573.07,0,0,1,2830.048,849.88c15.241,25.118,28.867,55.018,42.347,80.844,27.305,53.127,63.159,99.159,99.436,144.047,32.494,39.069,69.27,74.157,110.656,103.814,4.6,3.3,9.582,6.672,14.489,9.9l6.163,3.913c97.181,58.655,197.581-66.884,116.586-148.8l-12.607-12.031a1.846,1.846,0,0,0-.475-.441l-1.3-1.013c-16.2-12.1-31.791-24.638-47.458-37.6-33.633-28.59-68.77-57.631-95.445-92.957-9.732-12.055-19.77-26.786-28.2-39.942-17.108-26.549-30.69-55.648-47.667-82.26l-11.478-18.107C2901.8,644.894,2794.446,551.311,2672.35,492.972c-172.516-80.859-371.984-113.639-561.22-99.106-237.916,21.719-457.42,126.043-672.09,222.738-23.213,10.479-46.357,20.652-69.546,30.719-117.445,48.37-236.689,104.178-351.021,152.3C898.92,848.813,775.956,892.1,647.126,903.768c-144.319,14-300.329-39.011-412.9-128.991C148.891,706.87,85.222,608.3,83.573,496.885c-.926-10.3.4-23.761.79-34.075l.2-4.267.548-4.258C98.989,338.31,149.729,215.2,250.76,150.427c82.706-53.961,188.174-51.066,275.5-7.3,34.56,17.408,70.86,39.617,94,70.215,57.268,72.154,26.529,173-19.648,242.794C571.732,491.609,621,532.148,649.492,497.22" transform="translate(0.001 1485.595)" fill="#c8001f"/>
                <path d="M435.149,502.138c78.783-63.969,160.738-158.624,165.679-265.076,2.992-71.088-37.634-136.08-92.341-177.86C413.89-17.738,274.823-17.428,174.1,47.041,15.338,144.712-22.276,348.466,51.934,511.837c52.816,117.7,154.361,207.042,271.137,258.079,116.761,51.425,249.719,61.9,372.963,26.728,77.261-20.017,154.822-49.621,226.952-81.732,121.033-53.4,232.587-114.492,350.91-166.615,163.065-70.71,328.682-149.352,497.789-199.506,182.889-54.121,383.108-80.49,555.6,10.712,122.241,63.911,212.342,189.828,284.511,310.119,81.31,136.934,153.629,281.442,260.052,403.9,75.98,89.083,174.466,167.657,289.942,203.487,110.817,31.5,170.883-121.906,65.128-172.962-11.885-5.193-23.193-9.878-35.156-14.494-115.006-52.172-201.64-146.224-275.181-249.064-121.392-170.882-215.95-369.026-380.479-511.284C2213.06,45.47,1784.3,189.716,1440.23,349.091c-68.721,31.228-143.5,66.617-212.094,98.17C1110.516,502.124,995.384,567.383,880.3,620.626c-79.321,36.858-160.539,69.313-245.4,89.083-124.695,29.943-262.986-2.245-368.551-76.446C170.844,567.524,101.444,460.2,99.019,342.69c-3.2-90.731,39.845-180.571,116.9-229.372,74.346-49.33,176.623-52.521,248.007,3.516,112.771,83.855,64.9,194.483-16.569,283.507a517.617,517.617,0,0,1-52.307,49.771c-33.488,27.751,4.481,77.479,40.1,52.026" transform="translate(59.013 1589.8)" fill="#e485b1"/>
                <path d="M105.716,974.977c-29.89,57.583-37.241,139.125,31.189,172.739,32.314,15.765,66.908,15.832,101.448,14.683,122.154-7.8,229.779-70.016,330.32-133.016,281.374-186.676,542.74-406.737,851.016-546.774,91.42-42.963,186.225-78.332,282.853-107.427,145.425-43.894,298.486-70.04,449.686-55.6,106.287,9.49,206.45,39.181,305.963,80.815,138.8,57.981,277.47,137.942,425.256,181.293C2923.753,593.567,2965.3,603.8,3007,610.054c47.041,6.585,95.915,6.28,143.2-2.827,84.757-15.934,163.841-60.861,220.871-125.654a297.919,297.919,0,0,0,23.615-30.157c31.49-43.632,20.444-105.419-24.886-134.785-43.234-29.968-105.623-13.025-130.266,26.5-3.259,3.68-6.173,7.366-9.81,11.119-56.143,59.663-136.293,84.588-216.837,71.253-174.388-26.214-329.065-112.184-497.188-172.929C2118.644,99.255,1735.259,191.01,1368.61,378.1,1094.495,516.35,855.575,710.876,613.465,894.7c-114.919,83.031-271.288,202.969-417.226,195.457-13.66-.887-29.492-3.423-38.036-12.937-5.242-7.429-6.711-17.888-5.3-28.745a142.4,142.4,0,0,1,12.288-42.536l1.086-2.449c16.327-40.485-40.121-66.423-60.56-28.513" transform="translate(322.886 858.48)" fill="#e55420"/>
                <path d="M3267.5,795.457c2.585-.781,2.022-.635,2.488-.79.441-.131.218-.116.248-.15-.029-.024,0-.048-.078-.053l-.451,0c-92.38,10.212-169.985-40.286-226.026-111.146-8.006-10.159-24.653-32.727-32.256-43.443-71.553-98.64-157.319-202.925-259.082-278.59C2615.283,258,2450.462,201.312,2286.093,167.78c-122.149-26.03-248.642-37.1-373.235-23.732-244.54,25.089-464.4,140.648-679.538,248.943l-208.909,105.88c-141.017,72.286-275.089,158.667-404.4,248.768-63.164,43.419-126.256,89.165-191.243,127.943-31.7,18.64-64.023,35.384-94.194,43.947-28.634,8.156-53.578,8.321-77.993-6.638-53.806-28.013-102.253-77.377-99.421-135.944,9.664-56.453-70.482-73.532-84.578-17.825-9.058,50.484,8.03,103.974,36.732,144.828C135.916,941.9,172.236,970.9,211.261,993.46c121.029,75.108,268.136-31.81,369.613-93.505,97.894-62.9,194.895-127.662,294.956-185.439,113.556-69.381,292.085-148.489,413.933-205.946,105.143-49.121,208.642-97.782,316.336-138.112,170.586-66.025,354.45-96.4,534.8-62.612,140.294,26.04,287.222,68.251,406.975,143.853,122.507,76.548,203.022,188.193,278.848,312.384,22.49,38.211,47.468,75.549,76.819,109.449A520.765,520.765,0,0,0,3015.7,972.478c83.7,54.887,192.335,83.1,293.147,63.547,3.952-.766,7.628-1.479,11.919-2.541l6.595-1.649,3.8-1.023c67.412-16.589,108.139-86.687,87.982-153.387A121.9,121.9,0,0,0,3267.5,795.457" transform="translate(269.983 1157.924)" fill="#ffe637"/>
                <path d="M273.891,403.526c34.521,6.8,70.976-.6,101.065-18.412,41.8-24.8,67.819-68.072,82.818-112.46,27.237-75.306,17.9-168.433-50.663-220.081C354.018,12.829,289,6.942,226.438,17.431,164.389,28.1,106.758,70.766,76.082,125.188c-34.482,58.8-45.64,126.663-42.8,193.265C37.638,464.018,148.6,584.058,279.3,632.98a625.071,625.071,0,0,0,208.356,42.051c98.422-.9,193.877-35.864,282.678-71.514C1222.355,419.688,1845.318-30.614,2335.756,208.49,2514.484,296.933,2641.874,445.2,2739.633,619.2c123.191,212.114,206.082,400.259,453.885,493.876,107.15,41.319,218.893,60.3,332.833,57.68l13.156-.645c123.119-9.96,122.24-186.9-3.191-193.615-134.888-1.557-280.942-23.508-393.485-100.274-36.7-25.017-66.239-56.545-94.315-90.746C2957.206,672.975,2880.494,520.03,2785,399.3,2683.112,265.957,2549.631,153.676,2396.5,83.5,2087.482-63.6,1731.1,35.639,1435,165.27c-193.309,83.885-377.245,186.613-564.2,280.3C755.072,501.133,579.986,592.238,453.041,578.21c-149.9-10.867-317.145-91.653-333.463-255.838-7.322-71.025,9.926-159.385,67.393-202.61C219.9,94.25,265.619,87.394,306.628,92.5c67.965,7.9,101.05,59.319,90.625,125.16a218.586,218.586,0,0,1-20.293,63.222c-17.142,35.573-50.765,60.255-87.73,55.6-44.006-8.651-60,54.766-15.338,67.039" transform="translate(126.484 1650.028)" fill="#5a3681"/>
                <path d="M212.136,523.93c35.345,11.793,77.59.722,103.572-24.9,26.3-25.385,39.559-57.971,47.865-91.628,13.049-45.708-1.823-96.895-37.183-128.453-61.627-57.336-165.005-52.676-220.871,10.571-41.775,46.75-55.27,105.72-57.253,165.863C45.919,544.155,91.985,632.23,165.2,682.195c93.84,65.186,215.343,77.876,324.526,59.125,78.725-16.215,152.785-47.657,224.867-79.157,404.9-178.51,954.418-529.2,1400.08-495.5,138.2,14.751,276.694,79.569,380.819,167.522,71.112,60.507,127.3,137.166,179,221.264,78.294,128.4,155.927,281.834,272.48,391.206,206.567,198.9,471.536,255.46,729.707,117.581,27.121-14.683,53.534-31.558,77.741-51A89.89,89.89,0,0,0,3648.618,868.09c-19.072,12.9-37.906,25.269-58.388,35.975-111.941,60.716-246.344,81.387-365.849,31.742-105.919-43.555-194.4-116.519-265.517-206.707-90.775-113.45-164.933-261-264.479-377.046-87.977-105.075-203.061-189.459-330.568-240.171-51.081-20.182-102.8-37.755-156.825-48.515-186-35.7-379.228,6.435-554.281,63.93-293.734,98.054-563.965,249.573-838.166,389.038-89.131,44.8-179.9,90.542-273.116,123.681-158.687,63.067-414.85,23.707-419.912-184.435-.141-40.218,9.645-88.438,36.194-118.59,24.566-28.7,65.967-37.062,100.279-22.306,30.559,12.457,49.48,41.469,42.134,73.28-1.14,3.879-1.717,8.336-2.939,12.132-8.578,29.027-18.829,54.281-44.844,62.9a40.943,40.943,0,0,1-18.339.732l-1-.213-.31-.073c.1.039-.393-.131.548.194-39.132-12.617-61.467,43.972-21.1,60.294" transform="translate(185.451 1487.509)" fill="#5ba8d9"/>
                <path d="M196.842,638.847c28.828-14.94,52-39.312,59.14-72.673,6.158-35.384-4.481-72.382-33.1-94.184-30-22.941-75.268-16.758-102.956,4.733C83.514,503.368,67.119,547.6,63.463,590.308c-6.755,67.286,19.988,138.1,72.227,181.958C175.772,807,227.115,825.2,277.982,832.6,398.559,851.087,518.046,811.46,624.794,761.6,747.268,703.835,862.836,631.316,978.511,564c224.77-133.18,463.074-254.747,717.589-310.687,548.583-121.838,874.665,60.25,1182.389,511.875,29.972,42.279,66.069,95.018,101.351,134.3,97.991,110.278,236.4,191.835,387.685,195.244,55.566,1.236,112.131-6.313,164.3-26.82,48.278-17.786,72.538-72.882,51.919-120.3-18.6-46.571-76.761-66.1-117.494-47.613C3323.127,942.376,3184.8,876.88,3089.7,768.672,3033.689,702.88,2981.406,627.9,2925.084,561.14,2586.646,146.465,2190.31,7.364,1669.449,140.85c-266.182,65.337-510.571,195.768-741.626,338.511-150.249,89.611-294.282,192.155-459.7,248.778-98.2,32.7-256.575,45.824-314.3-55.789-16.86-30.113-20.9-68.94-12.884-102.442,5.208-21,16.293-39.229,34.608-43.724,4-.611,6.294.015,7.909.931a8.552,8.552,0,0,1,2.628,2.507,32.33,32.33,0,0,1,3.409,7.438c8.074,18.868-5.649,35.544-20.415,44.263-36.8,19.27-11.9,74.511,27.771,57.525" transform="translate(240.309 1330.696)" fill="#4c982b"/>
                <path d="M89.653,871.725c-27.208,49.514-14.1,115.258,21.2,156.131,32.271,40.141,87.827,62.18,137.7,62.873,125.087,2.274,257.55-67.873,364.88-125.742C860.116,825,1094.095,655.062,1339.968,517.76c136.584-76.354,282.038-141.4,434.358-176.221,185.924-43.1,383.355-41.76,570.937-2.235,265.75,67.756,488.338,240.612,706.072,402.509,28.1,21.074,72.809,55.75,101.962,75.748,87.226,58.951,188.829,95.682,297.569,79.87,59.649-9.174,117.8-33.522,162.983-74.39a97.9,97.9,0,0,0,4.408-138.388c-37.522-41.9-104.517-37.556-136.754-5.95.17-.16-.019-.044-.019-.044a6.012,6.012,0,0,0-.7.451c-2.9,1.867-5.708,3.753-9.039,5.625-76.824,42.478-168.06,4.51-234.289-42.5-270.167-190.506-553.2-406.631-889.9-467.836-116.81-17.98-235.623-17.365-353.688-8.248-341.551,23.969-644.61,192.936-921.9,380.363-169.054,112-334.568,228.79-506.954,334.738-88.316,51.463-310.963,185.279-393.669,93.825-17.976-17.874-27.077-39.19-22.427-63.208a70.526,70.526,0,0,1,1.935-7.191l.063-.223c-.048.044.116-.276-.31.49,20.6-41.377-37.658-72.664-60.958-33.221" transform="translate(289.037 1004.832)" fill="#c42078"/>
            </g>
            </g>
        </g>
    </svg>
)
