import React, { useState, useRef, useEffect } from 'react'

import './VideoAndText.sass'

import playVideo from 'Images/playVideo'

import { useInView } from 'react-intersection-observer'

import { reportVideoPause, reportEvent } from 'Utils/analytics'

function VideoAndText({
    title,
    copy,
    video,
    videoPoster,
    decoration,
    scrollId
}) {
    const hasAutoPlayedRef = useRef()

    const { ref, inView } = useInView({
        threshold: 0.5,
    })

    useEffect(() => {
        if (inView) {
            if (!hasAutoPlayedRef.current) {
                try {
                    videoRef.current.play()
                    hasAutoPlayedRef.current = true
                    setIsplaying(true)
                } catch (e) {
                    console.log('error playing video', e)
                }
            }
        }else{
            try {
                videoRef.current.pause()
                hasAutoPlayedRef.current = false
                setIsplaying(false)
            } catch (e) {
                console.log('error pausing video', e)
            }
        }
    }, [inView])

    const [isPlaying, setIsplaying] = useState(false)
    const videoRef = useRef(null)

    useEffect(() => {
        videoRef.current?.addEventListener('pause', reportVideoPause)

        return () => videoRef.current?.removeEventListener('pause', reportVideoPause)
    }, [])

    const handleVideoControl = (isPlaying) => {
        if (isPlaying) {
            videoRef.current.pause()
            setIsplaying(false)
            videoRef.current.controls = false
        } else if (!isPlaying) {
            videoRef.current.play()
            setIsplaying(true)
            videoRef.current.controls = true
        }
    }

    const handleVideoSectionClick = () => {
        reportEvent({
            category: "User Interaction",
            action: "Clicked New DK Video"
        })
    }

    return (
        <div
            className="container"
            ref={ref}
            id={scrollId}
        >
            <div className="VideoAndText-content">
                <h2
                    className="VideoAndText-content-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {copy &&
                    <div
                        className="VideoAndText-content-copy"
                        dangerouslySetInnerHTML={{ __html: copy }}
                    />
                }
            </div>
            <div className="VideoAndText-video">
                { decoration &&
                    <div
                        className="VideoAndText-video-decoration"
                        style={{
                            backgroundImage: `url(${ decoration.mediaItemUrl })`,
                        }}
                    />
                }
                <div
                    className="VideoAndText-video-cont"
                    onClick={handleVideoSectionClick}
                >
                    {video ?
                        <video
                            ref={videoRef}
                            poster={videoPoster ? videoPoster.mediaItemUrl : null}
                            className={`${isPlaying ? 'playing' : ''}`}
                            controls
                            controlsList="nodownload"
                        >
                            <source src={video.mediaItemUrl} type="video/mp4" />
                        </video>
                        :
                        <div className="placeholderBox" />

                    }
                    <div className={`playButton ${isPlaying ? 'playing' : ''}`}
                        onClick={() => handleVideoControl(isPlaying)}
                    >
                        {playVideo}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoAndText
