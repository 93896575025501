import React, { useContext, useEffect } from 'react'
import { MainContext } from "Utils/context"

import { useInView } from 'react-intersection-observer'
import {
    isShittyBrowser,
    useMainContext
} from 'Utils'

const FlexibleContentRow = ({
    Component,
    row,
    className,
}) => {
    const {
        setActiveSection,
    } = useContext(MainContext)

    const { ref, inView } = useInView({
        threshold: 0.15,
    })

    const { isMobile } = useMainContext()

    const _isShittyBrowser = isShittyBrowser()

    useEffect(() => {
        if ( inView ) {
            setActiveSection(row.scrollId)
        }
    }, [inView, row.scrollId, setActiveSection])

    return (
        <div
            className={`${className} ${(inView || _isShittyBrowser || isMobile) ? 'in-view' : ''}`}
            ref={ref}
        >
            <Component
                {...row}
            />
        </div>
    )
}

export default FlexibleContentRow