// import React, { useState, useRef, useEffect } from 'react'

// import './VideoTabs.sass'

// import Logo from 'Images/logo'
// import playVideo from 'Images/playVideo'

function VideoTabs({
    splitTitle,
    videos
}) {
    return null 

    // const [category, setCategory ] = useState("Customers")
    // const [ isPlaying, setIsplaying ] = useState(false)

    // const videoRef = useRef(null)

    // const {
    //     firstPart,
    //     secondPart
    // } = splitTitle

    // const {
    //     customerVideo,
    //     customerVideoPoster,
    //     employeeVideo,
    //     employeeVideoPoster,
    // } = videos

    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.load()
    //         setIsplaying(false)
    //         videoRef.current.controls = false
    //     }
    // }, [category])

    // const handleVideoControl = (isPlaying) => {
    //     if (isPlaying) {
    //         videoRef.current.pause()
    //         setIsplaying(false)
    //         videoRef.current.controls = false
    //     } else if (!isPlaying) {
    //         videoRef.current.play()
    //         setIsplaying(true)
    //         videoRef.current.controls = true
    //     }
    // }

    // const categories = ["Customers", "Employees"]

    // return (
    //     <>
    //         <div className="container">
    //             <div className="VideoTabs-splitTitle">
    //                 <span dangerouslySetInnerHTML={{ __html: firstPart }}/>
    //                 { Logo }
    //                 <span dangerouslySetInnerHTML={{ __html: secondPart }}/>
    //             </div>
    //         </div>
    //         <div className="VideoTabs-tabs">
    //                 { categories.map(cat => {
    //                     return (
    //                         <div 
    //                             className={`VideoTabs-tabs-tab ${cat === category ? 'selected' : ''}`}
    //                             onClick={() => setCategory(cat)}
    //                             key={cat}
    //                         >
    //                             <h4><span>For</span>{cat}</h4>
    //                             <h4><span>For</span>{cat}</h4>
    //                         </div>
    //                     )
    //                 })}
    //         </div>
    //         <div className="VideoTabs-videoCont">
    //             <video ref={videoRef} poster={ category === 'Customers' ? customerVideoPoster ? customerVideoPoster.mediaItemUrl : null : employeeVideoPoster ? employeeVideoPoster.mediaItemUrl : null } controlsList="nodownload">
    //                 <source 
    //                     src={ category === 'Customers' ? customerVideo ? customerVideo.mediaItemUrl : null : employeeVideo ? employeeVideo.mediaItemUrl : null } 
    //                     type="video/mp4" 
    //                 />
    //             </video>
    //             <div 
    //                 onClick={() =>  handleVideoControl(isPlaying)}
    //                 className={`playButton ${isPlaying ? 'playing' : ''}`}
    //             >
    //                 {playVideo}
    //                 <p>Watch now</p>
    //             </div>
    //         </div>
    //     </>
    // )
}

export default VideoTabs
