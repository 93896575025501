import { useEffect, useRef, useState } from 'react'
import Button from 'Components/Button/Button'

import Blobs1 from 'Images/blobs1'
import Blobs2 from 'Images/blobs2'

import "./Slide.sass"

export default function Slide({
    slide,
    isActive,
    handleClick,
    num,
    hasMultiple
}) {
    const {
        title,
        placeholderImage,
        video,
        colourScheme,
    } = slide

    const hasTrackedVideoPlay = useRef(false)

    const [isPlaying, setIsplaying] = useState(false)
    const videoRef = useRef(null)
    const videoPristineRef = useRef(true)

    useEffect(() => {
        if (!isActive && !videoPristineRef.current) {
            videoRef.current.pause()
            videoRef.current.currentTime = 0
            setIsplaying(false)
        }

        setTimeout(() => {
            if (videoRef.current) {
                videoRef.current.addEventListener('play', () => {
                    if (!hasTrackedVideoPlay.current) {
                        hasTrackedVideoPlay.current = true
                    }
                })
            }
        }, 100)
    }, [isActive])

    const handleVideoControl = isPlaying => {
        videoPristineRef.current = false

        if (isPlaying) {
            videoRef.current.pause()
            setIsplaying(false)
            videoRef.current.controls = false
        } else if (!isPlaying) {
            videoRef.current.play()
            setIsplaying(true)
            videoRef.current.controls = true
        }
    }

    const label = <>
        <span>Watch Now</span>
        <i className="fe fe-play" />
    </>

    let primaryColour = ''
    let secondaryColour = ''
    let textColour = ''

    switch (colourScheme) {
        case 'yellow':
            primaryColour = '#ffb612'
            secondaryColour = '#ebde00'
            textColour = '#9e720d'
            break
        case 'blue':
            primaryColour = '#007975'
            secondaryColour = '#73cff7'
            textColour = '#007975'
            break
        case 'pink':
            primaryColour = '#6e1245'
            secondaryColour = '#bc007b'
            textColour = '#6e1245'
            break
        default:
            primaryColour = '#145629'
            secondaryColour = '#32a43d'
            textColour = '#145629'
            break
    }

    return (
        <div
            className={`slide ${isPlaying ? 'playing' : ''}`}
            style={{
                borderColor: primaryColour
            }}
        >
            <div className="mobileTop">
                {(num & 1) ? Blobs1 : Blobs2}
                {title}
            </div>
            {hasMultiple ?
                <div
                    className={`overlay`}
                    onClick={handleClick}
                    style={{
                        backgroundColor: `${secondaryColour}B3`
                    }}
                >
                    <h2
                        dangerouslySetInnerHTML={{ __html: title }}
                        style={{
                            color: `${textColour}`
                        }}
                    />
                    <Button
                        label={label}
                        onClick={() => handleVideoControl(isPlaying)}
                        style={{
                            color: `${textColour}`
                        }}
                    />
                </div>
                : null}
            {slide.video ?
                <video
                    ref={videoRef}
                    poster={placeholderImage ? placeholderImage.mediaItemUrl : null}
                    className={`${isPlaying ? 'playing' : ''}`}
                    controls={!hasMultiple}
                    controlsList="nodownload"
                >
                    <source src={video.mediaItemUrl} type="video/mp4" />
                </video>
                :
                <div className="placeholderBox" />
            }
        </div>
    )
}