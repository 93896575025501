export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="148.624" height="159.581" viewBox="0 0 148.624 159.581">
    <g id="Group_945" data-name="Group 945" transform="translate(-100 -6234.618)">
      <g id="Group_913" data-name="Group 913" transform="translate(100 6263.353)">
        <g id="Group_912" data-name="Group 912">
          <path id="Path_998" data-name="Path 998" d="M13.519,96.569c17.95,26.78,40.593,46.18,77.8,25.78,37.238-20.4,46.557-58.848,22.808-89.056C90.383,3.1,57.024-12.078,22.924,11.643-6.41,32.029-5.371,68.392,13.519,96.569" transform="translate(0 0)" fill="#ffb612"/>
          <path id="Path_999" data-name="Path 999" d="M45.824,113.076c14.283,21.319,32.313,36.741,61.978,20.5,29.672-16.269,37.1-46.894,18.215-70.92-18.91-24.026-45.478-36.1-72.668-17.2-23.371,16.249-22.57,45.193-7.526,67.618" transform="translate(-22.369 -23.071)" fill="#ebde00"/>
        </g>
      </g>
      <g id="Group_921" data-name="Group 921" transform="translate(151.215 6234.618)">
        <g id="Group_920" data-name="Group 920" transform="translate(0)">
          <path id="Path_1002" data-name="Path 1002" d="M48.31,0C24.23,0,2.824,7.442.225,46.681s17.542,66.307,46.137,63.6S98.145,89.7,97.391,51.146C96.74,18,73.653,0,48.31,0" transform="translate(0)" fill="#6e1245"/>
          <path id="Path_1003" data-name="Path 1003" d="M51.586,17.157c-18.448,0-34.85,5.7-36.836,35.766s13.435,50.793,35.341,48.72,39.675-15.764,39.1-45.3c-.5-25.4-18.183-39.182-37.6-39.182" transform="translate(-3.152 -3.53)" fill="#bc007b"/>
        </g>
      </g>
    </g>
  </svg>
)
