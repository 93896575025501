import ReactGA from 'react-ga4'

export const initGA = () => {
    if (!window.GA_INITIALISED) {
        ReactGA.initialize('G-YRMWW6CGJL')
        window.GA_INITIALISED = true
    }
}

export const pageview = () => {
    ReactGA.send("pageview")
}

export const reportEvent = props => {
    ReactGA.event({
        ...props
    })
}

export const reportVideoPause = (event) => {
    const seconds = Math.round(event.target.currentTime)
    reportEvent({
        category: "User Interaction",
        action: "Paused Video",
        value: seconds
    })
}