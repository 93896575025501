import React, { useState } from 'react'
import { useIsMobile } from 'Utils'

import './Header.sass'

import Logo from 'Images/logo'
import HeaderMenu from './HeaderMenu'

function Header({
    isLoading
}) {
    const isMobile = useIsMobile()

    const tempMenuItems = [
        {
            id: 'about',
            offset: isMobile ? 100 : 150,
            label: 'About'
        },{
            id: 'brandStory',
            offset: isMobile ? 70 : 100,
            label: 'Brand Story'
        },{
            id: 'tvc',
            offset: -40,
            label: 'TVC'
        },{
            id: 'creative',
            offset: -250,
            label: 'Creative'
        },{
            id: 'humans',
            offset: 50,
            label: 'Humans'
        }
    ]

    const [menuOpen, setMenuOpen] = useState(false)

    const handleClick = () => {
        setMenuOpen(false)
    }

    const handleMenuToggle = () => {
        menuOpen ? setMenuOpen(false) : setMenuOpen(true)
    }

    return (
        <header className={`header ${menuOpen ? 'open' : ''}`}>
            <div className="container">
                <img className='logo' src={`https://fun-biology-api.medibank.yourcreative.com.au/wp-content/uploads/2022/07/medibank-live-better.png`} />
                <HeaderMenu 
                    menuItems={tempMenuItems} 
                    handleClick={handleClick} 
                    isLoading={isLoading}
                />
                <div className="toggle" onClick={() => handleMenuToggle()}>
                    <span />
                </div>
            </div>
        </header>
    )
}

export default Header
