import './ImageAndText.sass'

const ImageAndText = ({
    title,
    copy,
    image,
}) => {
    return (
        <div className="container">
            <div className="ImageAndText-content">
                <h1 
                    style={{
                        transitionDelay: '0.5s'
                    }}
                    dangerouslySetInnerHTML={{ __html: title }} 
                />
                { copy &&
                    <div 
                        style={{
                            transitionDelay: '0.7s'
                        }}
                        className="ImageAndText-content-copy" 
                        dangerouslySetInnerHTML={{ __html: copy }} 
                    />
                }
            </div>
            { image &&
                <div className="ImageAndText-image">
                    <img src={ image.mediaItemUrl } alt={ title } />
                </div>
            }
        </div>
    )
}

export default ImageAndText
