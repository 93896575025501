import React, { useContext, useEffect } from 'react'
import { MainContext } from "Utils/context"

import { Link } from "react-router-dom"

export default function HeaderMenu({
    menuItems,
    handleClick,
    isLoading
}) {
    const {
        activeSection,
    } = useContext(MainContext)

    const scrollToId = (e, item) => {
        if (e) {
            e.preventDefault()
        }

        const { id, offset } = item

        const element = document.getElementById(id)
        const bounding = element.getBoundingClientRect()

        const pos = (bounding.top + window.pageYOffset) - offset

        window.scrollTo({
            top: pos,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        if (!isLoading) {
            const hashIfExists = document.location.href.split('#')

            if (hashIfExists.length > 1) {
                const idToScrollTo = hashIfExists[1]

                const menuItemRow = menuItems.find(item => item.id === idToScrollTo)

                if (menuItemRow) {
                    setTimeout(() => {
                        scrollToId(null, menuItemRow)
                    }, 300)
                }
            }
        }
    }, [isLoading])

    return (
        <>
            <ul className="headerMenu">
                {menuItems.map((item, index) => {
                    const {
                        url,
                        label
                    } = item

                    const active = (activeSection === label)

                    return (
                        <li
                            key={index}
                            onClick={handleClick}
                            className={active ? 'active' : ''}
                        >
                            <Link
                                to={url || ''}
                                onClick={e => scrollToId(e, item)}
                            >
                                <span dangerouslySetInnerHTML={{ __html: label }} />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}
