import { useState, useRef, useEffect } from 'react'
import Slider from 'Components/Slider/Slider'
import Slide from './Slide'

import { useMainContext } from 'Utils'

import Blob1 from 'Images/blob1'
import Blob2 from 'Images/blob2'
import Blob3 from 'Images/blob3'
import Blob4 from 'Images/blob4'

import './VideoSlider.sass'

const VideoSlider = ({
    title,
    copy,
    slides,
    decoration1,
    decoration2,
    scrollId
}) => {
    const { isMobile, isTablet } = useMainContext()
    const hasMultiple = slides.length > 1

    const flickityRef = useRef(null)

    const [currentSlide, setCurrentSlide] = useState(0)

    const nextSlide = () => {
        if (flickityRef.current) {
            flickityRef.current.next(false)
            setCurrentSlide(flickityRef.current.selectedIndex)
        }
    }

    const previousSlide = () => {
        if (flickityRef.current) {
            flickityRef.current.previous(false)
            setCurrentSlide(flickityRef.current.selectedIndex)
        }
    }

    const selectSlide = index => {
        if (hasMultiple) {
            if (flickityRef.current) {
                flickityRef.current.selectCell(index)
                setCurrentSlide(index)
            }
        }
    }

    useEffect(() => {
        if (flickityRef.current) {
            flickityRef.current.on('change', index => {
                setCurrentSlide(index)
            })
        }
    }, [flickityRef])

    return (
        <div
            className="VideoSlider"
            id={scrollId}
        >
            <div className="container">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                {copy && <div dangerouslySetInnerHTML={{ __html: copy }} className="copy" />}
                {decoration1 &&
                    <div
                        className="VideoSlider-decoration1"
                        style={{
                            backgroundImage: `url(${decoration1.mediaItemUrl})`,
                        }}
                    />
                }
                {decoration2 &&
                    <div
                        className="VideoSlider-decoration2"
                        style={{
                            backgroundImage: `url(${decoration2.mediaItemUrl})`,
                        }}
                    />
                }
                <div className="sliderContainer">
                    {hasMultiple ?
                        <div className="controlContainer" onClick={() => previousSlide()}>
                            <i className="fe fe-chevron-left" />
                        </div>
                        : null}
                    <div className="sliderInner">
                        {(isMobile || isTablet) ?
                            slides.map((slide, index) =>
                                <Slide
                                    slide={slide}
                                    key={index}
                                    num={index}
                                    isActive={currentSlide === index}
                                    handleClick={() => isMobile ? setCurrentSlide(index) : null}
                                    hasMultiple={hasMultiple}
                                />
                            )
                            :
                            <Slider
                                options={{
                                    prevNextButtons: false,
                                    pageDots: false,
                                    wrapAround: true,
                                    cellAlign: 'left',
                                }}
                                className="Slider"
                                flickityRef={ref => flickityRef.current = ref}
                            >
                                {slides.map((slide, index) =>
                                    <Slide
                                        slide={slide}
                                        key={index}
                                        num={index}
                                        isActive={currentSlide === index}
                                        hasMultiple={hasMultiple}
                                    />
                                )}
                            </Slider>
                        }
                    </div>
                    {hasMultiple ?
                        <div className="controlContainer" onClick={() => nextSlide()}>
                            <i className="fe fe-chevron-right" />
                        </div>
                        : null}
                </div>
                {hasMultiple ?
                    <div className="iconContainer">
                        {slides.map((slide, index) => {
                            const {
                                title,
                                colourScheme,
                            } = slide

                            const blob = index === 0 ? Blob1 : index === 1 ? Blob2 : index === 2 ? Blob3 : Blob4

                            return (
                                <div
                                    key={index}
                                    onClick={() => selectSlide(index)}
                                    className={colourScheme}
                                >
                                    {blob}
                                    <span
                                        dangerouslySetInnerHTML={{ __html: title }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default VideoSlider