export default (
  <svg xmlns="http://www.w3.org/2000/svg" width="147.388" height="160.228" viewBox="0 0 147.388 160.228">
    <g id="Group_946" data-name="Group 946" transform="translate(-106.215 -7100.675)">
      <g id="Group_915" data-name="Group 915" transform="translate(106.215 7100.675)">
        <g id="Group_914" data-name="Group 914" transform="translate(0)">
          <path id="Path_1000" data-name="Path 1000" d="M57.224,0C28.7,0,3.345,8.815.267,55.294s20.779,78.54,54.649,75.331,61.337-24.375,60.444-70.043C114.588,21.316,87.243,0,57.224,0" transform="translate(0)" fill="#73cff7"/>
          <path id="Path_1001" data-name="Path 1001" d="M56.279,17.157c-20.787,0-39.269,6.421-41.507,40.3s15.139,57.234,39.823,54.9S99.3,94.593,98.648,61.307c-.562-28.618-20.489-44.15-42.369-44.15" transform="translate(1.108 1.426)" fill="#007975"/>
        </g>
      </g>
      <g id="Group_911" data-name="Group 911" transform="translate(151.215 7150.437)">
        <g id="Group_910" data-name="Group 910" transform="translate(0)">
          <path id="Path_996" data-name="Path 996" d="M52.392,0C26.3,0,3.062,8.956.245,47.619s19.015,65.329,50,62.663,52.939-20.006,52.117-58C101.661,19.622,79.86,0,52.392,0" transform="translate(0)" fill="#32a43d"/>
          <path id="Path_997" data-name="Path 997" d="M85.252,45.981c-21.142,0-39.967,7.258-42.249,38.583s15.4,52.929,40.512,50.768,42.89-16.209,42.228-46.986c-.572-26.465-18.236-42.365-40.492-42.365" transform="translate(-32.648 -35.139)" fill="#145629"/>
        </g>
      </g>
    </g>
  </svg>
)
