import React, { useContext } from 'react'
import { MainContext } from "Utils/context"

// import Submission from 'Components/ModalTypes/Submission'

import './Modal.sass'

function Modal() {
    const {
        modalData,
        closeModal,
    } = useContext(MainContext)

    const {
        type,
        // data,
        isVisible,
    } = modalData

    let component = null

    // const commonProps = {
    //     data,
    //     isVisible,
    //     close: closeModal
    // }

    switch (type) {
        // case 'SUBMISSION':
        //     component = (
        //         <Submission
        //             {...commonProps}
        //         />
        //     )
        default:
            break
    }

    return (
        <div
            className={`Modal ${isVisible ? 'visible' : ''} ${type}`}
            onClick={closeModal}
            onKeyDown={closeModal}
            role="button"
            tabIndex={0}
        >
            <div
                className={type}
                onClick={e => e.stopPropagation()}
                onKeyDown={e => e.stopPropagation()}
                role="button"
                tabIndex={0}
            >
                {component}
            </div>
        </div>
    )
}

export default Modal
